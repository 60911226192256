import { useState } from "react";
import {
  MantineProvider,
  Paper,
  Text,
  Select,
  Button,
  Group,
  SimpleGrid,
  createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import Icon from './Icon';
import styleDefs from './styles';

const selectData = [1, 2, 3, 5, 8].map(x => ({value: x, label: `${x}`}));

const useStyles = createStyles(styleDefs);

export default function App() {
  const [ effort, setEffort ] = useState(0);
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      complexity: 1,
      ambiguity: 1,
      risk: 1,
      volume: 1,
      effort: 1
    }
  });

  const calcEffort = () => {
    const { complexity: c, risk: r, ambiguity: a, volume: v } = form.values;
    const baseValue = 1;
    const multipliers = [c, r, a, v].map(val => (val/10)+1);
    const initial = multipliers.reduce((acc, val) => acc*val, baseValue);

    switch (true) {
      case initial > 8:
        setEffort(13);
        break;
      case (initial > 5 && initial < 8):
        setEffort(8);
        break;
      case (initial > 3 && initial < 5):
        setEffort(5);
        break;
      case (initial > 2 && initial < 3):
        setEffort(3);
        break;
      case (initial > 1 && initial < 2):
        setEffort(2);
        break;
      default:
        setEffort(initial.toFixed(0));
    }
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Paper shadow="md" radius="lg">
        <div className={classes.wrapper}>
          <div className={classes.contacts}>
            <Text size="lg" weight={700} className={classes.title} sx={{ color: '#eee' }}>
              Estimating Effort with 
            </Text>
            <Text size="xl" weight={800} className={classes.title} sx={{ color: '#eee' }}>
              CRAVE
            </Text>
          </div>

          <form className={classes.form} onSubmit={(event) => event.preventDefault()}>
            <Text size="lg" weight={700} className={classes.title}>
              Set your values below.
            </Text>

            <div className={classes.fields}>
              <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {['complexity', 'risk', 'ambiguity', 'volume'].map(attr => <Select key={attr} label={<Icon icon={attr} />} placeholder="Select value..." data={selectData} {...form.getInputProps(attr)} />)}
              </SimpleGrid>

              <Text mt={10}>
                {effort} Story Point{effort === 1 ? '' : 's'} of Effort
              </Text>

              <Group position="right" mt="md">
                <Button type="button" variant="outline" onClick={() => {form.reset(); setEffort(0);}}>
                  Reset
                </Button>
                <Button type="submit" className={classes.control} onClick={calcEffort}>
                  Calculate
                </Button>
              </Group>
            </div>
          </form>
        </div>
      </Paper>
    </MantineProvider>
  );
}
