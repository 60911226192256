import { 
  IconAlertTriangle, 
  IconTopologyComplex, 
  IconFlask, 
  IconAbacus 
} from '@tabler/icons-react';
import {
  Text,
  Group,
  ThemeIcon,
  createStyles,
} from '@mantine/core';

const useStyles = createStyles((theme) => {
  return {
    icon: {
      border: 'none'
    },
  };
});

export default function Icon({icon}) {
  const { classes } = useStyles();
  const iconComponent = (key) => {
    const data = {
      complexity: <IconTopologyComplex />,
      risk: <IconAlertTriangle />,
      ambiguity: <IconAbacus />,
      volume: <IconFlask />
    };

    return data[key];
  };

  const capstr = (val) => {
    return `${val[0].toUpperCase()}${val.substr(1,)}`;
  };

  return (
    <Group>
      <ThemeIcon variant="default" className={classes.icon} radius="lg" size="lg">
        {iconComponent(icon)}
      </ThemeIcon>
      <Text>
        {capstr(icon)}
      </Text>
    </Group>
  );
}
